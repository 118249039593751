import React, { Component } from 'react';

import { observer } from 'mobx-react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

@observer
class Export extends Component {

    constructor(props) {
        super(props);
        this.state = {
            copying: false,
        };
    }

    copyJsonToClipboard() {
        this.setState({
            copying: true,
        });
        navigator.clipboard.writeText(this.props.state.json)
            .then(() => this.setState({
                copying: false,
            }));
    }

    saveJsonToFile() {
        // https://stackoverflow.com/questions/13405129/javascript-create-and-save-file
        const blob = new Blob(
            [this.props.state.json],
            {type: 'application/json'}
        );
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = 'data.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    render() {
        const state = this.props.state;
        return (
            <Form>
                <Row>
                    <Col>
                        <Form.Control
                            as='textarea'
                            value={state.json}
                            rows={20}
                            readOnly
                        />
                    </Col>
                </Row>
                <Row>
                    <Button
                        onClick={this.saveJsonToFile.bind(this)}
                        style={{margin: '1em 0.5em 1em 1em'}}
                        variant='primary'
                    >
                        Save
                    </Button>
                    <Button
                        onClick={this.copyJsonToClipboard.bind(this)}
                        style={{margin: '1em 0.5em 1em 0.5em'}}
                        variant='secondary'
                    >
                        {
                            (this.state.copying)
                            ? (
                                <span>
                                <Spinner
                                    as='span'
                                    animation='border'
                                    size='sm'
                                />
                                Copying...
                                </span>
                            )
                            : (
                                'Copy'
                            )
                        }
                    </Button>
                </Row>
            </Form>
        );
    }

}

export default Export;
