import {
    action,
    computed,
    observable,
    runInAction,
} from 'mobx';

import axios from 'axios';

export default class Process {
    rootStore;
    @observable id;
    @observable hostId;
    @observable state;
    @observable coordinator;
    @observable owned;

    constructor(rootStore, id, hostId, owned) {
        this.rootStore = rootStore;
        runInAction(() => {
            this.id = id;
            this.hostId = hostId;
            this.owned = owned;
        })
    }

    @computed get name() {
        if (this.owned) {
            return this.id;
        } else {
            return '*' + this.id;
        }
    }

    @computed get host() {
        return this.rootStore.hosts[this.hostId];
    }

    @computed get url() {
        return this.host.url;
    }

    @action setId(id) {
        this.id = id;
    }

    @action setHostId(hostId) {
        this.hostId = hostId;
    }

    @action setState(state) {
        this.state = state;
    }

    @action setCoordinator(coordinator) {
        this.coordinator = coordinator;
    }

    @action update() {
        if (this.host.state) {
            this.getStatus()
                .then(response => runInAction(() => {
                    if (response.status === 200 && response.data === 'arrancado') {
                        this.state = true;
                    } else {
                        this.state = false;
                    }
                }))
                .catch(error => runInAction(() => this.state = false));
        } else {
            this.state = false;
            this.coordinator = '-';
        }
        this.getCoordinator()
            .then(response => runInAction(() => {
                if (response.status === 200) {
                    const coordinator = parseInt(response.data);
                    if (coordinator >= 0) {
                        this.coordinator = coordinator;
                    } else {
                        this.coordinator = '-';
                    }
                } else {
                    this.coordinator = undefined;
                }
            }))
            .catch(error => runInAction(() => this.coordinator = undefined));
    }

    getCoordinator() {
        return axios.get(
            this.url + 'coordinador',
            {
                params: {
                    proceso: this.id,
                },
            });
    }

    getStatus() {
        return axios.get(
            this.url + 'estado',
            {
                params: {
                    proceso: this.id,
                },
            });
    }

    start() {
        return axios.post(
            this.url + 'arrancar',
            '' + this.id,
            {
                headers: {
                    'Content-Type': 'text/plain',
                },
            }
        );
    }

    stop() {
        return axios.post(
            this.url + 'parar',
            '' + this.id,
            {
                headers: {
                    'Content-Type': 'text/plain',
                },
            }
        );
    }

    delete() {
        if (this.owned) {
            return axios.post(
                this.url + 'eliminarProceso',
                '' + this.id,
                {
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                }
            ).then(() => runInAction(() => {
                this.rootStore.processes[this.id] = null;
            }));
        } else {
            this.rootStore.processes[this.id] = null;
        }
    }

}
