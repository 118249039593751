import React, { Component } from 'react';

import Badge from 'react-bootstrap/Badge';

class Status extends Component {

    render() {
        switch (this.props.state) {
            case true:
                return (<Badge pill variant='success'>online</Badge>);
            case false:
                return (<Badge pill variant='danger'>offline</Badge>);
            default:
                return (<Badge pill variant='warning'>unknown</Badge>);
        }
    }

}

export default Status;
