import {
    action,
    computed,
    observable,
    runInAction,
} from 'mobx';

import axios from 'axios';

export default class Host {
    rootStore;
    @observable id;
    @observable ip;
    @observable state;
    @observable owned;

    constructor(rootStore, id, ip, owned) {
        this.rootStore = rootStore;
        runInAction(() => {
            this.id = id;
            this.ip = ip;
            this.owned = owned;
        });
    }

    @computed get name() {
        if (this.owned) {
            return this.ip;
        } else {
            return '*' + this.ip;
        }
    }

    @computed get url() {
        return 'http://' + this.ip + ':8080/PracticaObligatoria/servicio/';
    }

    @action setId(id) {
        this.id = id;
    }

    @action setIp(ip) {
        this.ip = ip;
    }

    @action update() {
        this.ping()
            .then(response => runInAction(() => {
                if (response.status === 200 && response.data === 'pong') {
                    this.state = true;
                } else {
                    this.state = false;
                }
            }))
            .catch(error => runInAction(() => this.state = false));
    }

    ping() {
        return axios.get(this.url + 'ping');
    }

    reset() {
        if (this.owned) {
            return axios.post(
                this.url + 'reiniciar',
                '',
                {
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                }
            );
        } else {
            return new Promise(resolve => resolve());
        }
    }

    addProcesses(number, startingId, owned) {
        if (this.owned && owned) {
            return axios.post(
                this.url + 'crearProcesos',
                '' + startingId + '$' + number,
                {
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                }
            );
        } else {
            return new Promise(resolve => resolve());
        }
    }

    addRemoteProcesses(number, startingId, ip, owned) {
        if (this.owned && owned) {
            return axios.post(
                this.url + 'crearProcesos',
                '' + startingId + '$' + number + '$' + ip,
                {
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                }
            );
        } else {
            return new Promise(resolve => resolve());
        }
    }

}
