import React, { Component } from 'react';

import { observer } from 'mobx-react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';

@observer
class Import extends Component {

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.state = {
            dragging: false,
            error: false,
        };
    }

    onDragEnter(event) {
        this.setState({
            dragging: true,
        });
    }

    onDragLeave(event) {
        this.setState({
            dragging: false,
        });
    }

    loadFromFile(file) {
        const reader = new FileReader();
        reader.onload = () => {
            if (!this.props.state.import(reader.result)) {
                this.setState({
                    error: true,
                });
            } else {
                this.setState({
                    error: false,
                });
            }
        };
        reader.readAsText(file);
    }

    loadFromDrop(event) {
        // https://developer.mozilla.org/es/docs/DragDrop/Drag_and_Drop/drag_and_drop_archivo
        let file = undefined;
        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                if (event.dataTransfer.items[i].kind !== 'file') {
                    file = event.dataTransfer.items[i].getAsFile();
                    if (file.type !== 'application/json') {
                        file = undefined;
                    } else {
                        break;
                    }
                }
            }
            event.dataTransfer.items.clear();
        } else {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                if (file.type === 'application/json') {
                    file = event.dataTransfer.files[i];
                    break;
                }
            }
            event.dataTransfer.clearData();
        }
        this.loadFromFile(file);
        event.preventDefault();
    }

    openFileSelector(event) {
        this.fileInput.current.click();
    }

    onFileSelected(event) {
        if (event.target.files.length > 0) {
            this.loadFromFile(event.target.files[0]);
        }
    }

    render() {
        return (
            <Container>
                <Alert
                    variant='danger'
                    show={this.state.error}
                    onClose={() => this.setState({error: false})}
                    dismissible
                >
                    <Alert.Heading>Invalid file</Alert.Heading>
                    <p>
                        The selected file doesn't have the required format.
                    </p>
                </Alert>
                <Jumbotron
                    onDragEnter={this.onDragEnter.bind(this)}
                    onDragOver={(event) => event.preventDefault()}
                    onDragLeave={this.onDragLeave.bind(this)}
                    onDrop={this.loadFromDrop.bind(this)}
                >
                    <h2 style={{textAlign: 'center'}}>Drag a file here</h2>
                    <h6 style={{textAlign: 'center'}}>or</h6>
                    <div style={{textAlign: 'center'}}>
                        <Button
                            onClick={this.openFileSelector.bind(this)}
                            variant='primary'
                            size='lg'
                        >
                            Select file
                        </Button>
                    </div>
                    <input
                        onChange={this.onFileSelected.bind(this)}
                        style={{'visibility': 'hidden'}}
                        ref={this.fileInput}
                        type='file'
                        accept='application/json'
                    />
                </Jumbotron>
            </Container>
        );
    }

}

export default Import;
