import React, { Component } from 'react';

import { observer } from 'mobx-react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

import Status from './Status';

@observer
class Hosts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ip: '',
            valid: false,
        };
    }

    changeIpFieldValue(event) {
        this.setState({
            ip: event.target.value,
            valid: this.isIpFieldValid(event.target.value),
        });
    }

    isIpFieldValid(ip) {
        return this.props.state.isValidIp(ip);
    }

    formatIp(ip) {
        return this.props.state.formatIp(ip);
    }

    submit() {
        if (this.state.valid) {
            this.props.state.addHost(this.formatIp(this.state.ip), true);
            this.setState({
                ip: '',
                valid: false,
            });
        }
    }

    submitInspect() {
        if (this.state.valid) {
            this.props.state.addHost(this.formatIp(this.state.ip), false);
            this.setState({
                ip: '',
                valid: false,
            });
        }
    }

    handleOnKeyPress(event) {
        if (event.key === 'Enter') {
            this.submit();
            event.preventDefault();
        }
    }

    render() {
        const state = this.props.state;
        return (
            <Container>
                <Form
                    style={{margin: '0 0 1em 0'}}
                    onKeyPress={this.handleOnKeyPress.bind(this)}
                >
                    <Row>
                        <Col sm={8}>
                            <Form.Control
                                value={this.state.ip}
                                onChange={this.changeIpFieldValue.bind(this)}
                                isValid={this.state.valid}
                                placeholder='IP'
                            />
                        </Col>
                        <Col sm={2}>
                            <Button
                                onClick={this.submit.bind(this)}
                                disabled={!this.state.valid}
                                variant='primary'
                                block
                            >
                                Add
                            </Button>
                        </Col>
                        <Col sm={2}>
                            <Button
                                onClick={this.submitInspect.bind(this)}
                                disabled={!this.state.valid}
                                variant='primary'
                                block
                            >
                                Inspect
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>IP</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            state.hosts.map(host => host && (
                                <tr key={host.id}>
                                    <td>{host.id}</td>
                                    <td>{host.name}</td>
                                    <td><Status state={host.state}/></td>
                                </tr>
                            ))
                        }
                        {
                            (state.hosts.every(x => !x)) && (
                                <tr>
                                    <td colSpan='3'>
                                        No hosts yet.
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Container>
        );
    }

}

export default Hosts;
