import {
    action,
    computed,
    observable,
    runInAction,
} from 'mobx';

import Host from './Host';
import Process from './Process';

export default class RootState {
    interval;
    @observable hosts = [undefined];
    @observable processes = [undefined];

    constructor() {
        this.interval = setInterval(this.update, 1000);
    }

    @computed get json() {
        const obj = {
            hosts: [],
            processes: [],
        };
        obj.hosts = this.hosts.map(host => host && ({
            id: host.id,
            ip: host.ip,
            owned: host.owned,
        }));
        obj.processes = this.processes.map(proc => proc && ({
            id: proc.id,
            host: proc.hostId,
            owned: proc.owned,
        }));
        return JSON.stringify(obj, null, '\t');
    }

    @action addHost(ip, owned) {
        const id = this.hosts.length;
        const host = new Host(this, id, ip, owned);
        host.reset();
        this.hosts.push(host);

    }

    @action addProcess(host, owned) {
        const id = this.processes.length;
        this.processes.push(new Process(this, id, host, owned));
        const ip = this.hosts[host].ip;
        for (let i = 0; i < this.hosts.length; i++) {
            if (i === host) {
                this.hosts[i].addProcesses(1, id, owned);
            } else {
                if (this.hosts[i]) {
                    this.hosts[i].addRemoteProcesses(1, id, ip, owned);
                }
            }
        }
    }

    @action import(json) {
        const obj = JSON.parse(json);
        const hosts = [undefined];
        const processes = [undefined];
        if (!obj || !obj.hosts || !obj.processes) {
            return false;
        }
        if (obj.hosts[0]) {
            return false;
        }
        for (let i = 1; i < obj.hosts.length; i++) {
            const host = obj.hosts[i];
            if (!host || !host.id || !host.ip || !host.owned) {
                return false;
            }
            if (!this.isValidIp(host.ip) || host.id !== i) {
                return false;
            }
            hosts.push(new Host(this, host.id, this.formatIp(host.ip), host.owned));
        }
        if (obj.processes[0]) {
            return false;
        }
        for (let i = 1; i < obj.processes.length; i++) {
            const proc = obj.processes[i];
            if (!proc) {
                processes.push(null);
                continue;
            }
            if (!proc.id || !proc.host || !proc.owned) {
                return false;
            }
            if (proc.id !== i || proc.host < 0 || proc.host >= hosts.lenght) {
                return false;
            }
            processes.push(new Process(this, proc.id, proc.host, proc.owned));
        }
        const promises = [];
        for (const host of this.hosts) {
            if (host) {
                promises.push(host.reset());
            }
        }
        this.hosts = hosts;
        for (const host of this.hosts) {
            if (host) {
                promises.push(host.reset());
            }
        }
        Promise.all(promises).then(() => {
            runInAction(() => this.processes = processes);
            for (const proc of this.processes) {
                if (proc) {
                    const ip = proc.host.ip;
                    for (let i = 0; i < this.hosts.length; i++) {
                        if (i === proc.hostId) {
                            runInAction(() => this.hosts[i].addProcesses(1, proc.id, proc.owned));
                        } else {
                            if (this.hosts[i]) {
                                runInAction(() => this.hosts[i].addRemoteProcesses(1, proc.id, ip, proc.owned));
                            }
                        }
                    }
                }
            }
        });
        return true;
    }

    @action.bound update() {
        for (let host of this.hosts) {
            if (host) {
                host.update();
            }
        }
        for (let proc of this.processes) {
            if (proc) {
                proc.update();
            }
        }
    }

    isValidIp (ip) {
        const regex = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
        const groups = regex.exec(ip);
        if (!groups) {
            return false;
        }
        for (let i = 1; i <= 4; i++) {
            const byte = parseInt(groups[i], 10);
            if (isNaN(byte) || byte > 255) {
                return false;
            }
        }
        return true;
    }

    formatIp(ip) {
        const regex = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
        const groups = regex.exec(ip);
        if (!groups) {
            return false;
        }
        const bytes = [];
        for (let i = 1; i <= 4; i++) {
            bytes[i - 1] = parseInt(groups[i], 10);
            if (isNaN(bytes[i - 1]) || bytes[i - 1] > 255) {
                return false;
            }
        }
        return bytes[0] + '.' + bytes[1] + '.' + bytes[2] + '.' + bytes[3];
    }
}
