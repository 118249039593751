import React, { Component } from 'react';

import { observer } from 'mobx-react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

import Process from './Process';

@observer
class Processes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            host: -1,
            valid: false,
        }
    }

    changeHostFieldValue(event) {
        this.setState({
            host: parseInt(event.target.value),
            valid: this.isHostFieldValid(parseInt(event.target.value)),
        });
    }

    isHostFieldValid(id) {
        return id > 0 && id < this.props.state.hosts.length;
    }

    submit() {
        if (this.state.valid) {
            this.props.state.addProcess(this.state.host, true);
            this.setState({
                host: -1,
                valid: false,
            });
        }
    }

    submitInspect() {
        if (this.state.valid) {
            this.props.state.addProcess(this.state.host, false);
            this.setState({
                host: -1,
                valid: false,
            });
        }
    }

    handleOnKeyPress(event) {
        if (event.key === 'Enter') {
            this.submit();
            event.preventDefault();
        }
    }

    render() {
        const state = this.props.state;
        return (
            <Container>
                <Form
                    style={{margin: '0 0 1em 0'}}
                    onKeyPress={this.handleOnKeyPress.bind(this)}
                >
                    <Row>
                        <Col sm={8}>
                            <Form.Control
                                as='select'
                                value={this.state.host}
                                onChange={this.changeHostFieldValue.bind(this)}
                                isValid={this.state.valid}
                            >
                                <option disabled value={-1}>
                                    Select host...
                                </option>
                                {
                                    state.hosts.map(host => host && (
                                        <option
                                            key={host.id}
                                            value={host.id}
                                        >
                                            {host.ip}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                        </Col>
                        <Col sm={2}>
                            <Button
                                onClick={this.submit.bind(this)}
                                disabled={!this.state.valid}
                                variant='primary'
                                block
                            >
                                Add
                            </Button>
                        </Col>
                        <Col sm={2}>
                            <Button
                                onClick={this.submitInspect.bind(this)}
                                disabled={!this.state.valid}
                                variant='primary'
                                block
                            >
                                Inspect
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>IP</th>
                            <th>Status</th>
                            <th>Coordinator</th>
                            <th>Controls</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            state.processes.map(proc => proc && (
                                <Process
                                    key = {proc.id}
                                    process = {proc}
                                />
                            ))
                        }
                        {
                            (state.processes.every(x => !x)) && (
                                <tr>
                                    <td colSpan='5'>
                                        No processes yet.
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Container>
        );
    }

}

export default Processes;
