import React, { Component } from 'react';

import { observer } from 'mobx-react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import Export from './Export';
import Hosts from './Hosts';
import Import from './Import';
import Processes from './Processes';

@observer
class App extends Component {

    render() {
        return (
            <Container>
                <Navbar bg='light' expand='lg' style={{margin: '1em 0'}}>
                    <Navbar.Brand>Manager</Navbar.Brand>
                </Navbar>
                <Tab.Container defaultActiveKey='hosts'>
                    <Row>
                        <Col sm={3}>
                            <Nav variant='pills' className='flex-column'>
                                <Nav.Item>
                                    <Nav.Link eventKey='hosts'>Hosts</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey='processes'>Processes</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey='import'>Import...</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey='export'>Export...</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey='hosts'>
                                    <Hosts state={this.props.state}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey='processes'>
                                    <Processes state={this.props.state}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey='import'>
                                    <Import state={this.props.state}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey='export'>
                                    <Export state={this.props.state}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        );
    }
}

export default App;
