import React, { Component } from 'react';

import { observer } from 'mobx-react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import {
    IoIosPause,
    IoIosPlay,
    IoIosTrash,
} from 'react-icons/io';

import Status from './Status';

@observer
class Process extends Component {

    render() {
        const proc = this.props.process;
        return (
            <tr>
                <td>{proc.name}</td>
                <td>{proc.host.name}</td>
                <td>
                    <Status state={proc.state}/>
                </td>
                <td>{proc.coordinator || '-'}</td>
                <td>
                    <ButtonToolbar>
                        <ButtonGroup>
                            <Button
                                size = 'sm'
                                disabled = {proc.state !== false || !proc.owned}
                                onClick = {proc.start.bind(proc)}
                            >
                                <IoIosPlay/>
                            </Button>
                            <Button
                                size = 'sm'
                                disabled = {proc.state !== true || !proc.owned}
                                onClick = {proc.stop.bind(proc)}
                            >
                                <IoIosPause/>
                            </Button>
                        </ButtonGroup>
                        &nbsp;
                        <Button
                            size = 'sm'
                            disabled = {!proc.owned}
                            onClick = {proc.delete.bind(proc)}
                        >
                            <IoIosTrash/>
                        </Button>
                    </ButtonToolbar>
                </td>
            </tr>
        );
    }

}

export default Process;
